<script setup lang="ts">
const { $gsap } = useNuxtApp();
const animate = () => {
  const feelConfidentSelector = $gsap.utils.selector('#feel-confident');
  const feelConfidentTimeline = $gsap.timeline({
    scrollTrigger: {
      trigger: '#feel-confident',
      start: 'top 60%',
      once: true,
    },
  });

  feelConfidentTimeline
    // Heading and subtitle animation
    .fromTo(feelConfidentSelector('.title'), { opacity: 0, y: 10 }, { opacity: 1, y: 0, duration: 1 }, 'heading')
    .fromTo(feelConfidentSelector('.subtitle'), { opacity: 0, y: -10 }, { opacity: 1, y: 0, duration: 1 }, 'heading')
    .to(feelConfidentSelector('img'), { opacity: 1, scale: 1, duration: 1 }, '<0.5');
};

onMounted(() => {
  animate();
});

onUnmounted(() => {
  $gsap.killTweensOf('#feel-confident *'); // Optimized cleanup
});
</script>

<template>
  <div id="feel-confident" class="bg-[url('/images/landing/bg-landing-confident.svg')] bg-cover bg-center bg-no-repeat bg-top py-16 md:py-24 px-4">
    <div class="max-w-3xl mx-auto text-center">
      <UIHeading size="h2" styleSize="h2" customClasses="title opacity-0 mb-4">{{ $t('landing.feedConfidentDecision') }}</UIHeading>
      <p class="subtitle opacity-0 max-w-xl mx-auto mb-8 md:mb-12">{{ $t('landing.careerCoachValidatesChoices') }}</p>
      <NuxtImg src="/images/landing/jobs-and-skills.webp" role="presentation" class="w-full mx-auto opacity-0 scale-90" />
    </div>
  </div>
</template>
